import React, { useEffect, useState } from 'react';
import styled, { css } from 'styled-components';
import { useFormik } from 'formik';
import { graphql, useStaticQuery } from 'gatsby';
import ContactCalculator from './ContactCalculator';
import { formatPrice } from '../utils/formatPrice';
import { up } from 'styled-breakpoints';

const StyledCalculator = styled.div`
  ${up('md')} {
    width: 400px;
    height: auto;
    right: 0;
    top: 0;
    position: absolute;
    border-radius: 4px;
    box-shadow: 0 60px 135px rgba(0, 0, 0, 0.14), 0 15px 65px rgba(0, 0, 0, 0.14);
  }

  background: white;
`;

const Header = styled.header`
  padding: 20px;
  display: flex;
  align-items: center;

  i {
    color: #333333;
    display: inline-block;
    margin-right: 15px;
    font-size: 30px;
  }

  h4 {
    margin-bottom: 0;
    font-size: 20px;
    text-transform: uppercase;
    font-weight: 600;
  }

  span {
    display: inline-block;
    position: relative;
    z-index: 1;

    &:after {
      content: '';
      width: 100%;
      height: 3px;
      position: absolute;
      transform: scaleX(1);
      display: block;
      left: 0;
      top: auto;
      bottom: 6px;
      z-index: -1;
      background: #ff1053;
    }
  }
`;

const StyledForm = styled.form`
  padding: 0 50px;
  margin-bottom: 20px;
`;

const IconInput = styled.i`
  font-size: 22px;
  padding-left: 5px;
`;

const StyledSelect = styled.select`
  padding-left: 40px;
`;

const TotalResume = styled.div``;

const Footer = styled.div`
  margin: 30px 20px 0;
  overflow: hidden;
  height: 0;
  min-height: 0;
  opacity: 0;
  transition: all 0.3s linear;

  ${({ hasPrice }) =>
    hasPrice &&
    css`
      overflow: visible;
      min-height: 150px;
      opacity: 1;
      height: auto;
    `};
`;

const CalculateResumeWrapper = styled.div`
  background: #fafafa;
  padding: 15px 20px;
  border-radius: 4px;

  background: #ff1053;
  background: linear-gradient(135deg, #ff1053 0%, #ff4f0f 100%);
  background: linear-gradient(135deg, #ff1053 0%, rgba(255, 79, 15, 0.88) 100%);
  padding: 20px;
  background: #ff1053;
  background: #3452ff;
  background: linear-gradient(to bottom right, #0f8fff, #607ddd);
  background: #ff1053;
  background: #192548;
  background: #3452ff;
  background: #ff1053;

  ${TotalResume} {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
`;

const AsteriskInfo = styled.small`
  font-size: 10px;
  text-align: right;
  display: block;
`;

const PriceTaxInfo = styled.small`
  font-size: 10px;
  display: block;
  text-align: right;
  line-height: 1;
  margin-top: 5px;
  color: white;
`;

const Price = styled.span`
  font-size: 30px;
  color: white;
  font-weight: 600;
  display: block;
`;

const Total = styled.div`
  line-height: 1;
  font-size: 20px;
  color: white;
`;

const ContactWrapper = styled.div`
  margin-top: 20px;

  button {
    background: white;
    border: 1px solid white;
    color: #333 !important;
    box-shadow: 0 20px 38px rgba(0, 0, 0, 0.16) !important;
    transition: all 0.3s ease-in-out;

    &:hover {
      background: white;
      border: 1px solid white;
      color: #333 !important;
    }
  }
`;

const CalculateWrapper = styled.div`
  margin-top: 40px;

  button {
    background: transparent;
    color: #ff1053 !important;
    border: 1px solid #ff1053;
    transition: all 0.3s ease-in-out;

    &:hover {
      background: #ff4f0f;
      background: linear-gradient(125deg, #ff4f0f, #ff1053);
      color: white !important;
    }
  }
`;

const Calculator = ({ id }) => {
  id = 'rotulos';
  const {
    allDataJson: { nodes: data }
  } = useStaticQuery(graphql`
    fragment PricingJsonConnectionFragment on DataJsonConnection {
      nodes {
        id
        variants {
          items {
            name
            price
          }
          name
          id
          label
        }
      }
    }

    query Calculator {
      allDataJson {
        ...PricingJsonConnectionFragment
      }
    }
  `);

  const categorySelected = data.find(({ id: category }) => (category = id));
  const [category, setCategory] = useState(null);
  const [isSubmitted, setIsSubmitted] = useState(false);
  const [price, setPrice] = useState(null);
  const [isOpenContactCalculator, setIsOpenContactCalculator] = useState(false);

  const formik = useFormik({
    initialValues: {
      variant: '',
      item: ''
    },

    onSubmit: values => {
      const { variant: variantSelected, item } = values;

      setIsSubmitted(true);
      setPrice(null);
      categorySelected.variants.forEach(({ name, items }) => {
        if (name === variantSelected) {
          const itemSelected = items.find(({ name: itemName }) => item === itemName);
          if (itemSelected) {
            setPrice(itemSelected.price);
          }
        }
      });
    }
  });

  useEffect(() => {
    // init data
    const [defaultVariant] = categorySelected.variants;
    formik.setFieldValue('variant', defaultVariant.name);
    setCategory(defaultVariant);
  }, []);

  const handleCategory = e => {
    const variantSelected = categorySelected.variants.find(({ name }) => name === e.target.value);
    variantSelected && setCategory(variantSelected);

    formik.setFieldValue('variant', e.target.value);
    formik.setFieldValue('item', '');
    setPrice(null);
  };

  const handleItem = e => {
    formik.setFieldValue('item', e.target.value);
    formik.handleSubmit();
  };

  return (
    <StyledCalculator>
      <Header>
        <i className="icon-Calculator-2" />
        <h4>
          Calcular <span>presupuesto</span>
        </h4>
      </Header>

      <StyledForm onSubmit={formik.handleSubmit}>
        <label>Selecciona tipo</label>
        <select onChange={handleCategory} value={formik.values.variant}>
          {categorySelected.variants.map(({ name, label }, index) => (
            <option key={index} value={name}>
              {label}
            </option>
          ))}
        </select>

        <label className="mt-4 d-block">Medidas (cm)</label>
        <div className="input-icon">
          <IconInput className="icon-Ruler-2" />
          <StyledSelect onChange={handleItem} value={formik.values.item}>
            <option selected value={''} disabled>
              Seleccione medida
            </option>

            {category &&
              category.items.map(({ name }, index) => (
                <option key={index} value={name}>
                  {name}
                </option>
              ))}
          </StyledSelect>
        </div>

        {!isSubmitted && (
          <CalculateWrapper>
            <button type="submit" className="btn btn--primary type--uppercase">
              Calcular
            </button>
          </CalculateWrapper>
        )}
      </StyledForm>

      <Footer hasPrice={!!price}>
        <CalculateResumeWrapper>
          <TotalResume>
            <Total>Total*</Total>
            <div>
              <Price>{price ? formatPrice(price) : '- €'}</Price>
              <PriceTaxInfo>Precio sin IVA</PriceTaxInfo>
            </div>
          </TotalResume>

          <ContactWrapper>
            <button
              onClick={() => setIsOpenContactCalculator(true)}
              type="submit"
              className="btn btn--primary type--uppercase"
            >
              Pedir presupuesto
            </button>
          </ContactWrapper>
        </CalculateResumeWrapper>

        <AsteriskInfo>* Estimación de precio</AsteriskInfo>
      </Footer>

      <ContactCalculator
        serviceName={id}
        items={formik.values}
        price={price}
        isOpen={isOpenContactCalculator}
        onModalClose={() => setIsOpenContactCalculator(false)}
      />
    </StyledCalculator>
  );
};

export default Calculator;
