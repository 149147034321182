import React from 'react';
import { graphql } from 'gatsby';
import SEO from '../components/seo';
import Layout from '../components/layout';
import { useIntl } from 'gatsby-plugin-intl';
import styled, { keyframes } from 'styled-components';
import Calculator from '../components/Service/Calculator';
import Portfolio from '../components/Service/Portfolio';
import OurClients from '../components/Service/OurClients';
import Claim from '../components/Service/Claim';
import { down } from 'styled-breakpoints';
import Img from 'gatsby-image';
import VinylProducts from '../components/Service/VinylProducts';
import Breadcrumb from '../components/utils/Breadcrumb';

const StyledHeader = styled.section`
  padding-top: 120px;
  position: relative;
  min-height: 300px;
  //overflow: hidden;
  // background: linear-gradient(135deg,#ff5433 0%,#5689ff 100%);
`;

const BackgroundImage = styled.div`
  z-index: 1;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  overflow: hidden;
  display: inline-block;
  transform-origin: top right;

  .gatsby-image-wrapper {
    width: 100%;
    height: 100%;
  }
`;

const HeaderContainer = styled.div`
  z-index: 4;
  //position: relative;
  bottom: 50px;

  ${down('md')} {
    bottom: 20px;
  }
`;

const BackgroundGradient = styled.div`
  //background: radial-gradient(
  //  circle at left top,
  //  rgb(12, 180, 206) 9%,
  //  rgb(0, 108, 255) 48%,
  //  rgb(100, 66, 255) 91%
  //);
  //background: linear-gradient(135deg, #ff1053 0%, rgba(255, 79, 15, 0.88) 100%);
  //background: linear-gradient(135deg, #c3e0e5 0%, #192548 100%);
  //background-color: rgb(35, 175, 186);
  background: linear-gradient(-135deg, #a137bc 0%, #d83c48 100%);
  background-color: #ff1053;
  background: linear-gradient(135deg, #ff1053 0%, #fec22d 100%);
  //background-image: linear-gradient(-74deg, rgb(35, 175, 186) 0%, rgb(41, 141, 191) 68.55%, rgb(48, 107, 196) 100%)
  //background: linear-gradient(135deg, #ff5433 0%, #5689ff 100%);
  //opacity: 0.95;
  opacity: 0.9;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: 2;

  ${down('md')} {
    opacity: 0.8;
  }
`;

const Title = styled.h1`
  color: white;
  font-size: 60px;
  margin-bottom: 0 !important;
  font-weight: 700;
  line-height: 1;

  ${down('md')} {
    font-size: 40px;
  }
`;

const Description = styled.p`
  font-size: 16px;
  color: #f1f1f1;
  font-weight: 600;
  margin-bottom: 0;
  max-width: 500px;
`;

const ServicePortfolio = styled.section`
  margin-top: 85px;

  ${down('md')} {
    margin-top: 10px;
  }
`;

const ServicesClaim = styled.section`
  //margin-top: 115px;
  padding-top: 7.42857143em !important;
  background-color: #f7f7f7;
`;

const StyledCalculator = styled.div`
  position: relative;
  z-index: 6;
`;

const Service = ({ data }) => {
  const intl = useIntl();
  const [service] = data.allContentfulService.nodes;
  const portfolioImages = service.portfolioImages || [];

  const portfolioItems = portfolioImages.map(({ title, fluid, file }) => {
    const type = file.contentType.startsWith('video/') ? 'video' : 'image';
    const src = type === 'image' ? { ...fluid } : { url: file.url };

    return {
      title,
      type,
      ...src
    };
  });

  return (
    <Layout>
      <SEO
        title={intl.formatMessage({ id: 'title.service' }, { name: service.seoTitle })}
        lang={'es'}
        description={service.seoDescription.seoDescription}
      />

      <Breadcrumb data={[{ slug: service.slug, name: service.title }]} />

      {/*<StyledHeader className={'height-40'}>*/}
      <StyledHeader className={'height-30'}>
        <BackgroundGradient />
        <BackgroundImage>
          <Img fluid={service.headerImage.fluid} alt="Imagen de servicio" />
        </BackgroundImage>

        {/*<StyledCalculator className="container hidden-xs">*/}
        {/*  <Calculator id={service.slug} />*/}
        {/*</StyledCalculator>*/}

        <HeaderContainer className="pos-bottom pos-absolute container">
          <Title>{service.title}</Title>
          <Description>{service.subTitle}</Description>
        </HeaderContainer>
      </StyledHeader>

      {/*<div className="visible-xs">*/}
      {/*  <Calculator id={service.slug} />*/}
      {/*</div>*/}

      {portfolioItems.length > 0 && (
        <ServicePortfolio className="container-fluid">
          <Portfolio items={portfolioItems} />
        </ServicePortfolio>
      )}

      <Claim />

      {/*<section>*/}
      {/*  <VinylProducts />*/}
      {/*</section>*/}

      <OurClients />
    </Layout>
  );
};

export const serviceQuery = graphql`
  query($slug: String!) {
    allContentfulService(filter: { slug: { eq: $slug } }) {
      nodes {
        title
        subTitle
        slug
        description {
          content {
            content {
              value
            }
          }
        }
        shortDescription
        seoTitle
        seoDescription {
          seoDescription
        }

        headerImage {
          fluid(maxWidth: 1500) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }

        portfolioImages {
          title
          file {
            url
            contentType
          }
          fluid(maxWidth: 500) {
            ...GatsbyContentfulFluid_withWebp_noBase64
          }
        }
      }
    }
  }
`;

export default Service;
