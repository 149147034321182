import React from 'react';
import styled, { css } from 'styled-components';
import VisibilitySensor from 'react-visibility-sensor';
import { down } from 'styled-breakpoints';

const ServicesClaim = styled.section`
  //margin-top: 115px;
  padding-top: 7.42857143em !important;
  background-color: #f7f7f7;
`;

const ClaimTitle = styled.h3`
  padding-top: 10%;
  text-transform: none;
  letter-spacing: 0;
  font-size: 42px;
  line-height: 48px;
  font-weight: 700;
`;

const BoxBackground = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  height: 100.2%;
  width: 100.2%;
  background-size: cover;
  z-index: 1;
  background-position: center;
  transition: all 0.45s cubic-bezier(0.25, 1, 0.2, 1);
  opacity: 0;

  background: #ff4f0f;
  background: linear-gradient(to bottom right, #ff4f0f, #ff1053);

  &:after {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    content: ' ';
  }
`;

const BoxInnerContent = styled.div`
  position: relative;
  z-index: 10;
  min-height: 200px;
  display: flex;
  align-items: center;
  justify-content: center;

  h3 {
    font-size: 26px;
    line-height: 36px;
    font-weight: 400;
    margin-bottom: 8px;
    color: #444;
  }

  p {
    line-height: 32px;
    margin-bottom: 0;
    font-size: 16px;
    opacity: 0.75;
    color: #444;
  }

  i {
    font-size: 50px;
    line-height: 50px;
    margin-bottom: 25px;
    display: block;

    &:before {
      color: #ff4f0f;
      background: linear-gradient(to bottom right, #ff4f0f, #ff1053);
      background-clip: text;
      -webkit-background-clip: text;
    }
  }
`;

const Box = styled.div`
  position: relative;
  margin-bottom: 20px;

  &:before {
    box-shadow: 0 30px 90px #ff1053;
    transition: opacity 0.45s cubic-bezier(0.25, 1, 0.2, 1);
    border-radius: 5px;
    display: block;
    position: absolute;
    left: 1%;
    top: 1%;
    height: 98%;
    width: 98%;
    opacity: 0;
    content: '';
  }

  &:hover {
    ${BoxBackground} {
      opacity: 1;
    }

    &:before {
      opacity: 0.33;
    }

    ${BoxInnerContent} {
      h3,
      p {
        color: white;
      }

      i:before {
        color: white;
        background: transparent;
      }
    }
  }

  ${down('md')} {
    ${({ isVisible }) =>
      isVisible &&
      css`
        ${BoxBackground} {
          opacity: 1;
        }

        &:before {
          opacity: 0.33;
        }

        ${BoxInnerContent} {
          h3,
          p {
            color: white;
          }

          i:before {
            color: white;
            background: transparent;
          }
        }
      `};
  }
`;

const BoxContainer = styled.div`
  overflow: hidden;
  border-radius: 5px;
  padding: 10% 15%;
  position: relative;
`;

const Claim = () => {
  return (
    <ServicesClaim>
      <div className="container">
        <div className="row">
          <div className="col-md-4">
            <ClaimTitle>Un equipo excepcional y eficaz adaptado a ti.</ClaimTitle>
          </div>

          <div className="col-md-8">
            <div className="row">
              <div className="col-md-6">
                <VisibilitySensor minTopValue={150}>
                  {({ isVisible }) => (
                    <Box isVisible={isVisible}>
                      <BoxContainer>
                        <BoxBackground />

                        <BoxInnerContent>
                          <div>
                            <i className="icon-Over-Time2" />
                            <h3>Entrega a tiempo</h3>
                            <p>
                              Dedicamos un esfuerzo extra para que los trabajos se realicen a
                              tiempo.
                            </p>
                          </div>
                        </BoxInnerContent>
                      </BoxContainer>
                    </Box>
                  )}
                </VisibilitySensor>
              </div>

              <div className="col-md-6">
                <VisibilitySensor minTopValue={150}>
                  {({ isVisible }) => (
                    <Box isVisible={isVisible}>
                      <BoxContainer>
                        <BoxBackground />

                        <BoxInnerContent>
                          <div>
                            <i className="icon-Cool-Guy" />
                            <h3>Años de experiencia</h3>
                            <p>Contamos con más de 10 años de experiencia en el sector.</p>
                          </div>
                        </BoxInnerContent>
                      </BoxContainer>
                    </Box>
                  )}
                </VisibilitySensor>
              </div>

              <div className="col-md-6">
                <VisibilitySensor minTopValue={150}>
                  {({ isVisible }) => (
                    <Box isVisible={isVisible}>
                      <BoxContainer>
                        <BoxBackground />

                        <BoxInnerContent>
                          <div>
                            <i className="icon-Vector-5" />
                            <h3>Diseño personalizado</h3>
                            <p>Nos adaptamos a las necesidades de diseño que usted desee.</p>
                          </div>
                        </BoxInnerContent>
                      </BoxContainer>
                    </Box>
                  )}
                </VisibilitySensor>
              </div>

              <div className="col-md-6">
                <VisibilitySensor minTopValue={150}>
                  {({ isVisible }) => (
                    <Box isVisible={isVisible}>
                      <BoxContainer>
                        <BoxBackground />

                        <BoxInnerContent>
                          <div>
                            <i className="icon-Map2" />
                            <h3>Allá donde estés</h3>
                            <p>
                              Le entregamos el trabajo realizado donde quiera con un margen de
                              24/72h.
                            </p>
                          </div>
                        </BoxInnerContent>
                      </BoxContainer>
                    </Box>
                  )}
                </VisibilitySensor>
              </div>
            </div>
          </div>
        </div>
      </div>
    </ServicesClaim>
  );
};

export default Claim;
