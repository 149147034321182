import ReactModal from 'react-modal';
import React, { useState } from 'react';
import styled from 'styled-components';
import { useFormik } from 'formik';
import { FormattedMessage } from 'gatsby-plugin-intl';
import { formatPrice } from '../utils/formatPrice';
import { down } from 'styled-breakpoints';

const ModalHeader = styled.header`
  padding: 15px;
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
`;

const ModalTitle = styled.h5`
  line-height: 1.5;
  font-size: 20px;
  margin-bottom: 0 !important;
`;

const CloseButton = styled.button`
  background-color: transparent;
  appearance: none;
  margin: 0;
  padding: 5px;
  line-height: 15px;
  cursor: pointer;
  font-size: 15px;
  font-weight: 700;
  border: 1px solid #666666;
  border-radius: 50%;
  width: 30px;
  height: 30px;
`;

const StyledMessageAlert = styled.div`
  display: flex;
  align-items: center;
  ${({ hasError }) => hasError && 'border: 1px solid #ff1053'};
  ${({ hasSuccess }) => hasSuccess && 'border: 1px solid #36b30b'};
  border-radius: 4px;
  padding: 20px;

  i {
    font-size: 30px;
    margin-right: 20px;
    ${({ hasError }) => hasError && 'color: #ff1053'};
    ${({ hasSuccess }) => hasSuccess && 'color: #36b30b'};
  }
`;

const ModalBody = styled.div`
  padding: 15px;
`;

const ModalFooter = styled.footer`
  padding: 15px;
`;

const ModalDialog = styled.div`
  position: relative;
  max-width: 500px;
  margin: 1.75rem auto;
  width: auto;

  ${down('md')} {
    margin: 10px;
  }
`;

const ModalContent = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  width: 100%;
  pointer-events: auto;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid rgba(0, 0, 0, 0.2);
  outline: 0;
  border-radius: 4px;
  box-shadow: 0 60px 135px rgba(0, 0, 0, 0.14), 0 15px 65px rgba(0, 0, 0, 0.14);
`;

const StyledReactModal = styled(
  ({ modalClassName, className, onRequestClose, children, title, footer, ...props }) => (
    <ReactModal
      className={modalClassName}
      portalClassName={className}
      bodyOpenClassName="portalOpen"
      onRequestClose={onRequestClose}
      {...props}
    >
      <ModalDialog>
        <ModalContent>
          <ModalHeader>
            <ModalTitle>{title}</ModalTitle>
            <CloseButton onClick={onRequestClose}>X</CloseButton>
          </ModalHeader>

          <ModalBody>{children}</ModalBody>

          {footer && <ModalFooter>{footer}</ModalFooter>}
        </ModalContent>
      </ModalDialog>
    </ReactModal>
  )
).attrs({
  overlayClassName: 'Overlay',
  modalClassName: 'Modal'
})`
  .Overlay {
    position: fixed;
    inset: 0;
    z-index: 9999;
    overflow-x: hidden;
    overflow-y: auto;
    top: 0;
    width: 100%;
    height: 100%;

    opacity: 0;

    &:after {
      content: '';
      //background: black;
      //opacity: 0.5;
      background: rgba(0, 14, 53, 0.9) none repeat scroll 0% 0%;
      //background: rgba(255, 16, 83, 0.6) none repeat scroll 0 0;
      position: absolute;
      position: fixed;
      z-index: -1;
      width: 100%;
      height: 100%;
    }
  }

  .Modal {
    position: absolute;
    left: 0;
    right: 0;
    margin-left: auto;
    margin-right: auto;
    transform: translate(0, -25%);
  }

  //[class*='--after-open'] {
  .ReactModal__Overlay--after-open {
    opacity: 1;
    transition: opacity 0.15s linear;
  }

  .ReactModal__Overlay--before-close {
    opacity: 0;
  }

  .ReactModal__Content--after-open {
    transform: translate(0, 0);
    transition: transform 0.3s ease-out;
  }

  //&[class*='--before-close'] {
  .ReactModal__Content--before-close {
    transform: translate(0, -25%);
    transition: transform 0.3s ease-out;
  }
`;
ReactModal.setAppElement('body');

const StyledBill = styled.div`
  box-shadow: 0 25px 60px rgba(0, 0, 0, 0.17);
  padding: 20px;
  border-radius: 4px;
  margin-bottom: 40px;
  background: #5689ff;
  background: #3452ff;
  background: #ff1053;
  color: white;

  .col-xs-6 {
    width: 50%;
  }
`;

const BillItem = styled.div`
  font-weight: 600;
  font-size: 12px;
  line-height: 1;

  ${down('md')} {
    font-size: 16px;
  }
`;

const BillItemValue = styled.div`
  ${down('md')} {
    font-size: 16px;
  }
`;

const Items = styled.div`
  border-right: 1px solid #fff;
  box-shadow: 0 25px 60px rgba(0, 0, 0, 0.17);
  padding-top: 10px;
  padding-bottom: 10px;

  ${down('md')} {
    width: 50%;
    box-shadow: none;
    padding-left: 10px;
  }
`;

const TotalPrice = styled.div`
  font-size: 40px;
  line-height: 1;
  background: #fff;
  color: #333;
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 25px 60px rgba(0, 0, 0, 0.17);
  display: inline-block;
  margin-top: 10px;
`;

const StyledForm = styled.form`
  padding: 0 20px;

  button[type='submit'] {
    background: #192548;
    border: 0;
  }
`;

const ContactCalculator = ({
  serviceName,
  items,
  price,
  isOpen = false,
  onModalClose = () => {}
}) => {
  const [hasError, setHasError] = useState(false);
  const [hasSuccess, setHasSuccess] = useState(false);

  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      details: ''
    },

    onSubmit: async function (values) {
      try {
        setHasError(false);
        setHasSuccess(false);
        const result = {
          ...values,
          ...items,
          serviceName
        };
        console.log(result);

        const body = JSON.stringify(result);
        console.log(body);

        const response = await fetch(`/.netlify/functions/send-contact-service`, {
          method: 'POST',
          body
        });
        if (response.ok) {
          const data = response.json();
          setHasSuccess(true);
          formik.resetForm();
        } else {
          throw new Error(response);
        }
      } catch (e) {
        setHasError(true);
      }
    }
  });

  const handleModalClose = () => {
    setHasError(false);
    setHasSuccess(false);
    formik.resetForm();

    onModalClose();
  };

  return (
    <StyledReactModal
      isOpen={isOpen}
      title={<FormattedMessage id="serviceNameOf" values={{ serviceName }} />}
      onRequestClose={handleModalClose}
      closeTimeoutMS={300}
    >
      <StyledBill>
        <div className="row">
          <Items className="col-xs-6 col-md-6">
            <div className="mb-3">
              <BillItem>Tipo</BillItem>
              <BillItemValue>{items.variant}</BillItemValue>
            </div>
            <div>
              <BillItem>Medida(cm)</BillItem>
              <BillItemValue>{items.item}</BillItemValue>
            </div>
          </Items>

          <div className="col-xs-6 col-md-6">
            <div>Precio total</div>
            <TotalPrice>{formatPrice(price)}</TotalPrice>
          </div>
        </div>
      </StyledBill>

      <StyledForm onSubmit={formik.handleSubmit}>
        <div>
          <label htmlFor="name">Nombre de contacto*</label>
          <input
            type="text"
            name="name"
            id="name"
            placeholder="nombre"
            onChange={formik.handleChange}
            value={formik.values.name}
          />
        </div>

        <div className="mt-4">
          <label htmlFor="email">Correo electrónico*</label>
          <input
            type="email"
            name="email"
            id="email"
            onChange={formik.handleChange}
            placeholder="tucorreo@email.com"
            value={formik.values.email}
          />
        </div>

        <div className="mt-4">
          <label htmlFor="details">Comentario</label>
          <textarea
            name="details"
            id="details"
            onChange={formik.handleChange}
            placeholder="añade algún comentario si lo ves necesario"
            value={formik.values.details}
          />
        </div>

        <div className="mt-5 mb-4">
          <button type="submit" className="btn btn--primary type--uppercase">
            Solicitar presupuesto
          </button>
        </div>

        {(hasError || hasSuccess) && (
          <div className="mt-3">
            <StyledMessageAlert hasError={hasError} hasSuccess={hasSuccess}>
              {hasSuccess && (
                <>
                  <i className="icon-Mail-Send" />
                  <p>
                    ¡Gracias por pedir un presupuesto! Nos pondremos en contacto contigo lo antes
                    posible.
                  </p>
                </>
              )}
              {hasError && (
                <>
                  <i className="icon-Error-404Window" />
                  <p>
                    Parece que tenemos algún tipo de problema, vuelva a intentarlo más tarde.
                    Perdone las molestias
                  </p>
                </>
              )}
            </StyledMessageAlert>
          </div>
        )}
      </StyledForm>
    </StyledReactModal>
  );
};

export default ContactCalculator;
