import React from 'react';
import styled, { css, keyframes } from 'styled-components';

const ServiceClaimColorTitle = styled.h4`
  background-repeat: no-repeat;
  background-image: url(https://www.apple.com/v/ipad-pro/ac/images/overview/hero_manifesto_bg__c0zrbe748m82_large.jpg);
  background-size: cover;
  background-clip: text;
  -webkit-background-clip: text;
  color: transparent;
  font-size: 96px;
  line-height: 1;
  font-weight: 600;

  i {
    display: block;
    font-size: 42px;
  }
`;

const ProductWrapper = styled.div`
  background-color: #f5f5f7;
  height: 350px;
  //height: 300px;
  border-radius: 6px;
  position: relative;
  margin-bottom: 30px;

  .p {
    position: absolute;
    width: 100%;
    height: 100%;
    padding: 20px 0px;

    div {
      font-size: 30px;
    }
  }
`;

const ServiceTitle = styled.h3`
  font-weight: 400;
  margin: 0;
  font-size: 20px;
  padding: 0 10px;
  padding-left: 40px;
  padding-left: 20px;
`;

const ProductWrapperImage = styled.div`
  padding: 40px;
  width: 100%;
  height: 100%;
  max-height: 300px;
  //max-height: 255px;
  padding-bottom: 20px;

  padding: 20px;
  padding-bottom: 20px;
`;

const ViniloColor = styled.div`
  background-image: url(https://www.apple.com/v/mac-mini/j/images/shared/router_macos_bigsur__d5jyevp1wq82_large_2x.jpg);
  background-size: cover;
  width: 100%;
  height: 100%;
  box-shadow: 12px 12px 24px 0 rgba(0, 0, 0, 0.2), -12px -12px 24px 0 rgba(255, 255, 255, 0.5);
`;

const blur = keyframes`
  0%, 90%{
    filter: blur(0);
  }
  
  50%{
    filter: blur(7px);
  }
`;

const ViniloPizarranegra = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-shadow: 12px 12px 24px 0 rgba(0, 0, 0, 0.2), -12px -12px 24px 0 rgba(255, 255, 255, 0.5);
  .image {
    background-image: url(https://dbdzm869oupei.cloudfront.net/img/sticker/large/12671.jpg);
    background-size: 292%;
    background-position: 53%;
    width: 100%;
    height: 100%;
    background-position-y: 24%;
  }
`;

const ViniloPizarrablanca = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-shadow: 12px 12px 24px 0 rgba(0, 0, 0, 0.2), -12px -12px 24px 0 rgba(255, 255, 255, 0.5);

  .image {
    background-image: url(https://ramsboards.com/wp-content/uploads/2018/07/Safety-Dialog-Whiteboard.jpg);
    background-position-x: -246px;
    background-position-y: -134px;
    background-size: 490px;
    width: 100%;
    height: 100%;
  }
`;

const ViniloAntigraffiti = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-shadow: 12px 12px 24px 0 rgba(0, 0, 0, 0.2), -12px -12px 24px 0 rgba(255, 255, 255, 0.5);

  .image {
    background-image: url(https://www.lotum.es/imgs/proteccion-antigraffiti-3.jpg);
    background-size: 200%;
    width: 100%;
    height: 100%;
  }
`;

const ViniloSueloantideslizante = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-shadow: 12px 12px 24px 0 rgba(0, 0, 0, 0.2), -12px -12px 24px 0 rgba(255, 255, 255, 0.5);

  .image {
    background-image: url(https://lh3.googleusercontent.com/proxy/gRna9fP2ZKLLot9QVTdwqv6aEc5SS1XQjrZm8bW-8inCyEhQ-a61fxKEq5fkUyx-fUGrsdVILj3mWYS8hA4ZpB6QrlJ7ebt2KeMBiAbNftuKGMCWbcXQGEfERons_tz3-7UTBqWG86Hph_4XvLJHHxkXHeZWO8iDc7vK9CNIyWtLLtBgavcg64sEcJyhFFCiEuJLZ-_m_AtMmeAdoId_zWAgpzV9ZE9bVw);
    background-size: cover;
    width: 100%;
    height: 100%;
  }
`;

const ViniloPoster = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-shadow: 12px 12px 24px 0 rgba(0, 0, 0, 0.2), -12px -12px 24px 0 rgba(255, 255, 255, 0.5);

  .image {
    background-image: url(https://o.remove.bg/downloads/ce6c0882-01b4-469e-857c-00c8f076e780/Movie-Poster-Advertising-PSD-Mockup-removebg-preview.png);
    background-size: 220%;
    background-position: 50%;
    width: 100%;
    height: 100%;
  }
`;

const ViniloMicroperforado = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-shadow: 12px 12px 24px 0 rgba(0, 0, 0, 0.2), -12px -12px 24px 0 rgba(255, 255, 255, 0.5);

  .texture {
    background-image: url(https://www.transparenttextures.com/patterns/dark-denim-3.png);
    width: 100%;
    height: 100%;
    position: absolute;
    background-repeat: repeat;
    background-size: 38%;
    opacity: 0.5;
    background-image: url(https://png.pngtree.com/png-clipart/20200831/original/pngtree-simple-black-hexagon-honeycomb-grid-texture-background-png-image_5467097.jpg);
    background-size: 48%;
    opacity: 1;
    background-image: url('https://images-wixmp-ed30a86b8c4ca887773594c2.wixmp.com/f/92b5eda0-aa31-4cf2-a82e-5a191cffa21f/dabk1j9-86e9cf56-60f7-4692-b0ed-cbbb031ac0c8.png/v1/fill/w_1600,h_1200,strp/honeycomb_texture_2_by_zarodas_dabk1j9-fullview.png?token=eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJzdWIiOiJ1cm46YXBwOiIsImlzcyI6InVybjphcHA6Iiwib2JqIjpbW3siaGVpZ2h0IjoiPD0xMjAwIiwicGF0aCI6IlwvZlwvOTJiNWVkYTAtYWEzMS00Y2YyLWE4MmUtNWExOTFjZmZhMjFmXC9kYWJrMWo5LTg2ZTljZjU2LTYwZjctNDY5Mi1iMGVkLWNiYmIwMzFhYzBjOC5wbmciLCJ3aWR0aCI6Ijw9MTYwMCJ9XV0sImF1ZCI6WyJ1cm46c2VydmljZTppbWFnZS5vcGVyYXRpb25zIl19.4Pbs9yI_9BT8kx5hSqeSGWLWoHLvZkwphS7914-e8d0');
    background-image: url(https://cdn.picpng.com/honeycomb/honeycomb-texture-pattern-honey-64044.png);
  }

  .image {
    background-image: url(https://www.movilzona.es/app/uploads/2020/01/cartel-dia-sin-iva.jpg);
    background-size: cover;
    width: 100%;
    height: 100%;
  }
`;

const ViniloAcido = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  overflow: hidden;
  box-shadow: 12px 12px 24px 0 rgba(0, 0, 0, 0.2), -12px -12px 24px 0 rgba(255, 255, 255, 0.5);

  > div {
    background-image: url(https://image.freepik.com/free-photo/two-smiling-businessmen-shaking-hands-while-standing-office_109710-1799.jpg);
    background-size: cover;
    background-position-x: 110%;
    width: 100%;
    height: 100%;

    &:after {
      content: '';
      position: absolute;
      top: -10px;
      bottom: 0;
      right: 0;
      background: inherit;
      //filter: blur(7px);
      width: 130%;
      height: 110%;
      left: -10px;
      animation: ${blur} 2s ease 0s infinite;
    }
  }
`;

const Item = styled.div`
  width: 100%;
  height: 100%;
  padding: 20px;

  ${({ type }) =>
    type === 'acido' &&
    css`
      width: 100%;
      height: 100%;
      padding: 20px;
      backdrop-filter: blur(2px);
      background-color: rgba(255, 255, 255, 0.6);
    `}
`;

const VinylProducts = () => {
  return (
    <div className="container-fluid mt--2">
      <div className="row">
        <div className="col-md-4">
          <ServiceClaimColorTitle>
            Nos gusta dar color
            <i> a todo</i>
          </ServiceClaimColorTitle>
        </div>

        <div className="col-md-7 offset-md-1">
          <div className="row">
            {/*<div className="col-md-4">*/}
            {/*  <ProductWrapper>*/}
            {/*    <div className="p">*/}
            {/*      <div>Tu logo</div>*/}
            {/*      <div>Tu texto</div>*/}
            {/*    </div>*/}
            {/*    <Item type="acido" />*/}
            {/*  </ProductWrapper>*/}
            {/*</div>*/}

            <div className="col-md-4">
              <ProductWrapper>
                <ProductWrapperImage>
                  <ViniloColor />
                </ProductWrapperImage>

                <ServiceTitle>Vinilo de color</ServiceTitle>
              </ProductWrapper>
            </div>

            <div className="col-md-4">
              <ProductWrapper>
                <ProductWrapperImage>
                  <ViniloAcido>
                    <div />
                  </ViniloAcido>
                </ProductWrapperImage>

                <ServiceTitle>Translucido</ServiceTitle>
              </ProductWrapper>
            </div>

            <div className="col-md-4">
              <ProductWrapper>
                <ProductWrapperImage>
                  <ViniloMicroperforado>
                    <div className="texture" />
                    <div className="image" />
                  </ViniloMicroperforado>
                </ProductWrapperImage>

                <ServiceTitle>Mircroperforado</ServiceTitle>
              </ProductWrapper>
            </div>

            <div className="col-md-4">
              <ProductWrapper>
                <ProductWrapperImage>
                  <ViniloPoster>
                    <div className="image" />
                  </ViniloPoster>
                </ProductWrapperImage>

                <ServiceTitle>Mircroperforado</ServiceTitle>
              </ProductWrapper>
            </div>

            <div className="col-md-4">
              <ProductWrapper>
                <ProductWrapperImage>
                  <ViniloPizarranegra>
                    <div className="image" />
                  </ViniloPizarranegra>
                </ProductWrapperImage>

                <ServiceTitle>Pizarra negra</ServiceTitle>
              </ProductWrapper>
            </div>

            <div className="col-md-4">
              <ProductWrapper>
                <ProductWrapperImage>
                  <ViniloPizarrablanca>
                    <div className="image" />
                  </ViniloPizarrablanca>
                </ProductWrapperImage>

                <ServiceTitle>Pizarra blanca</ServiceTitle>
              </ProductWrapper>
            </div>

            <div className="col-md-4">
              <ProductWrapper>
                <ProductWrapperImage>
                  <ViniloAntigraffiti>
                    <div className="image" />
                  </ViniloAntigraffiti>
                </ProductWrapperImage>

                <ServiceTitle>Pizarra blanca</ServiceTitle>
              </ProductWrapper>
            </div>

            <div className="col-md-4">
              <ProductWrapper>
                <ProductWrapperImage>
                  <ViniloSueloantideslizante>
                    <div className="image" />
                  </ViniloSueloantideslizante>
                </ProductWrapperImage>

                <ServiceTitle>Suelo antideslizante</ServiceTitle>
              </ProductWrapper>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default VinylProducts;
